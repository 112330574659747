// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti_tabs_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 340px;
}

.noti_tabs_div div{
    font-size: 18px;
    font-family: Noto Sans;
    font-weight: 600;
    line-height: 28px;
    color: #C5C5C5;
    padding: 4px;
    cursor: pointer;
}

.activeTab{
    font-size: 15px;
    font-family: Noto Sans;
    font-weight: 700;
    line-height: 28px;
    padding: 4px;
    color: #FAFBFF;
    border-bottom: .4px solid #8b8b8b;
    width: 100%;
}

.ellipsis {
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
  }
  .container {
    overflow: hidden;
  }

  .notification-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 335px;
    padding: 5px;
    border-bottom: .5px solid grey;

  }`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,cAAc;IACd,iCAAiC;IACjC,WAAW;AACf;;AAEA;IACI,uBAAuB,EAAE,qBAAqB;IAC9C,mBAAmB,EAAE,oCAAoC;IACzD,gBAAgB,EAAE,kDAAkD;EACtE;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,8BAA8B;;EAEhC","sourcesContent":[".noti_tabs_div{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 16px;\n    width: 340px;\n}\n\n.noti_tabs_div div{\n    font-size: 18px;\n    font-family: Noto Sans;\n    font-weight: 600;\n    line-height: 28px;\n    color: #C5C5C5;\n    padding: 4px;\n    cursor: pointer;\n}\n\n.activeTab{\n    font-size: 15px;\n    font-family: Noto Sans;\n    font-weight: 700;\n    line-height: 28px;\n    padding: 4px;\n    color: #FAFBFF;\n    border-bottom: .4px solid #8b8b8b;\n    width: 100%;\n}\n\n.ellipsis {\n    text-overflow: ellipsis; /* enables ellipsis */\n    white-space: nowrap; /* keeps the text in a single line */\n    overflow: hidden; /* keeps the element from overflowing its parent */\n  }\n  .container {\n    overflow: hidden;\n  }\n\n  .notification-item {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: start;\n    width: 335px;\n    padding: 5px;\n    border-bottom: .5px solid grey;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
